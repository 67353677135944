@tailwind base;
@tailwind components;
@tailwind utilities;

.index :global .pintura-editor {
  --color-background: 255, 255, 255;
  --color-foreground: 10, 10, 10;
}

@media (prefers-color-scheme: dark) {
  .index :global .pintura-editor {
    --color-background: 10, 10, 10;
    --color-foreground: 255, 255, 255;
  }
}

*,
body {
  font-family: 'Poppins';
  pointer-events: auto !important;
  font-size: 1rem;
}

.docuseal-icon-image {
  content: url('/icons/ic-logout.svg');
}

@layer base {
  :root {
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --chart-6: #b9c8d9;
    --chart-7: #1d4f84;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

/* EMBLA */

.embla {
  max-width: 100%;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 35%;
}

.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.fields {
  background: red !important;
}

.base-button {
  background: red !important;
}

/* TEXT SHINE */

@layer utilities {
  .bg-apply-gradient {
    background-image: linear-gradient(
        14.76deg,
        #0177c1 17.16%,
        #1d4f84 46.08%,
        #233656 75%
      ),
      linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  }
  .gradient-text {
    background: linear-gradient(
      to right,
      #1d4f84 20%,
      #00affa 30%,
      #0190cd 40%,
      #1d4f84 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
  }

  @keyframes text-shine {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }

  .animate-text-shine {
    animation: text-shine 5s ease-in-out infinite alternate;
  }
}

/* DOC VIEWER */
#msdoc-renderer {
  height: calc(100vh - 100px);
}

/* Driver Tour */
.driver-popover-next-btn {
  font-family: 'Inter', serif !important;
  background: linear-gradient(#0177c1, #1d4f84, #233656) !important;
  text-align: center !important;
  padding: 8px 16px !important;
  font-size: 1rem !important;
  color: #fff !important;
  border-radius: 8px !important;
  border: none !important;
}

.driver-popover-prev-btn {
  font-family: 'Inter', serif !important;
  text-align: center !important;
  padding: 8px 16px !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  color: #1d4f84 !important;
  border: none !important;
}

.driver-popover-navigation-btns {
  display: grid !important;
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

#canvas-element-id {
  background-color: white;
  display: block;
}

.rpv-core__viewer {
  background-color: white !important;
}

.rpv-core__tooltip-body {
  z-index: 9999;
}
